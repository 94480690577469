<template>
	<v-slide-y-transition>
		<!--<div id="top-page" v-if="page" :key="random_key">-->
		<div id="top-page" v-if="page">
			<one-page :definition="page"></one-page>
		</div>
	</v-slide-y-transition>
</template>
<style scoped>
#top-page {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 2;
	background-color: #808080;
	padding: 100px;
}
</style>
<script>
import OnePage from './OnePage.vue';

export default {
	name: 'TopPage',
	components: {
		OnePage,
	},
	props: {
		page: {
			type: Object,
		},
	},
	computed: {
		window_height(){
			return window.innerHeight;
		},
		random_key(){
			return Math.random();
		},
	},
	methods: {
	},
};
</script>
