<template>
	<v-card v-if="display" :class="{
		selected: is_selected,
		'not-selected': ! is_selected,
		'can-click': can_click,
		'is-menu': mode == 'menu',
	}" @click="select_me()">
		<v-card-title>{{ $t("engine.name." + info.name) }}</v-card-title>
		<v-card-subtitle v-if="mode == 'full' && $te('engine.description.' + info.name)">{{ $t("engine.description." + info.name) }}</v-card-subtitle>
		<v-card-text v-if="mode == 'full'">
			<one-page v-if="info.summary_page" :definition="info.summary_page" :key="domain"></one-page>
		</v-card-text>
		<!--
		<v-card-actions v-if="mode == 'full'">
			<v-btn @click="expand()">Expand</v-btn>
		</v-card-actions>
		-->
	</v-card>
</template>
<style lang="scss" scoped>
@import "../css/menu.scss";
</style>
<script>
//import CardContent from './CardContent.vue';
import OnePage from "./OnePage";
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'OneEngine',
	data(){
		return {
			display: true,
		};
	},
	components: {
		//CardContent,
		OnePage,
	},
	props: {
		info: {
			type: Object,
		},
		mode: {
			type: String,
		},
	},
	computed: {
		...mapGetters([
			'selectedEngine',
			'domain',
		]),
		is_selected(){
			if(this.mode == 'full'){
				return false;
			}
			if(this.info.name != this.selectedEngine){
				return false;
			}
			return true;
		},
		can_click(){
			if(this.mode != 'menu'){
				return true;
			}
			if(this.is_selected){
				return false;
			}
			return true;
		},
	},
	methods: {
		...mapActions([
			'setPageLevel',
			'selectEngine',
		]),
		expand(){
			this.selectEngine(this.info.name);
			this.setPageLevel("sub_engine_flow");
		},
		select_me(){
			if(this.can_click){
				this.expand();
			}
		},
	},
};
</script>
