<template>
	<tool-tip position="left" :tooltipText="$t('tooltip.user_notification')">
		<template v-if="unread > 0">
			<v-badge :content="unread" color="error">
				<v-app-bar-nav-icon @click="show" variant="text" icon="mdi-bell" :class="{}"></v-app-bar-nav-icon>
			</v-badge>
		</template>
		<template v-if="unread == 0">
			<v-app-bar-nav-icon @click="show" variant="text" icon="mdi-bell" :class="{}"></v-app-bar-nav-icon>
		</template>
	</tool-tip>
	<v-dialog
		v-model="dialog"
	>
		<v-card class="user-notification-box">

			<user-notification-list
				:admin="false"
				@change_unread="update_unread_count"
			/>
		</v-card>
	</v-dialog>
</template>
<style>
.v-dialog .v-overlay__content > .v-card.user-notification-box {
	width: 600px;
	overflow-y: scroll;
	max-height: 500px;
}
</style>
<script>
import UserNotificationList from './UserNotificationList';

export default {
	name: 'UserNotificationIcon',
	data () {
		return {
			dialog: false,
			unread: 0,
		}
	},
	props: {
	},
	computed: {
	},
	components: {
		UserNotificationList,
	},
	methods: {
		async show(){
			this.dialog = true;

		},
		async update_unread_count(){
			let messages = (await this.$adminAPI.get_user_notifications()).messages;
			this.unread = messages.filter(m=>(m.read == 0)).length;
		},
	},
	async mounted(){
		await this.update_unread_count();
	},
}
</script>
