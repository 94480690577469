<template>
	<component :is="get_page_by_name(definition.type)" v-bind="{...definition.props, ...extra_props}"></component>
</template>
<script>
import { defineAsyncComponent } from "vue";

export default {
	name: 'OnePage',
	props: {
		definition: {
			type: Object,
		},
		extra_props: {
			type: Object,
			default: ()=>{
				return {};
			},
		},
	},
	methods: {
		get_page_by_name(name){
			return defineAsyncComponent(() => import("./pages/" + name));
		},
	},
	mounted(){
		/*
		console.log("definition below");
		console.log(this.definition);
		*/
		//console.log({...this.definition.props, ...this.extra_props});
	},
};
</script>
