<template>
	<v-dialog v-model="dialog" persistent class="change-password">
		<v-card width="400">
			<v-card-title>
				{{ $t('ui.change_password') }}
			</v-card-title>
			<v-card-text>
				<v-form>
					<v-container fluid>
						<v-row justify="center">
							<v-col cols="12" sm="12" >
								<v-text-field
									v-model="password"
									prepend-icon="mdi-lock"
									:append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
									:rules="[rules.required]"
									:type="show_password ? 'text' : 'password'"
									:label="$t('ui.new_password')"
									:hint="$t('message.enter_new_password')"
									@click:append="show_password = !show_password"
									hide-details
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" >
								<v-text-field
									v-model="password_again"
									prepend-icon="mdi-lock"
									:append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
									:rules="[rules.required]"
									:type="show_password ? 'text' : 'password'"
									:label="$t('ui.password_again')"
									:hint="$t('message.enter_password_again')"
									@click:append="show_password = !show_password"
									@keydown.enter.prevent="change_password"
									hide-details
								></v-text-field>
							</v-col>
							<v-progress-linear v-show="loading" indeterminate rounded height="6" ></v-progress-linear>
							<v-col cols="12" sm="6" >
								<v-btn @click="change_password">
									{{ $t("ui.change") }}
								</v-btn>
							</v-col>
							<v-col cols="12" sm="6" >
								<v-btn @click="cancel">
									{{ $t("ui.cancel") }}
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
		</v-card>
		<v-card width="400">
			<v-card-title>
				{{ $t("ui.mail_address") }}
			</v-card-title>
			<v-card-text>
				<v-text-field
					v-model="mail_address"
					type="text"
					hide-details
					:label="$t('ui.mail_address')"
				></v-text-field>
				<v-btn @click="update_mail_address">
					<v-icon>mdi-content-save</v-icon>
					<span>{{ $t('ui.save') }}</span>
				</v-btn>
			</v-card-text>
		</v-card>
		<v-card width="400" v-if="userAttributes.auth_2fa == 'yes'">
			<v-card-title>
				{{ $t("ui.totp") }}
			</v-card-title>
			<v-card-text>
				<center>
					<img :src="totp_image" class="totp-image" />
				</center>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<style>
.totp-image {
	width: 200px;
	height: 200px;
}
.change-password .v-input__control {
	display: inline-block;
}
.change-password .v-input__prepend {
	display: inline-block;
}
.change-password .v-input__append {
	display: inline-block;
}
</style>
<script>
import { mapGetters, mapActions } from 'vuex';
//import { sleep } from "../tools";

export default {
	name: 'ChangePassword',
	components: {
	},
	data () {
		return {
			password: "",
			password_again: "",
			loading: false,
			show_password: false,
			dialog: true,
			totp_image: "",
			mail_address: "",
		}
	},
	computed: {
		...mapGetters([
			'domainAbilities',
			'userAttributes',
		]),
		rules(){
			return {
				required: value => !!value || this.$t('message.need_value'),
			};
		},
	},
	async mounted(){
		//console.log('change password box');
		this.mail_address = await this.$adminAPI.get_mail_address_for_user();
		if(this.userAttributes.auth_2fa == 'yes'){
			this.totp_image = await this.$adminAPI.get_2fa_image();
			console.log(this.totp_image);
		}
	},
	props: {
	},
	methods: {
		async cancel(){
			this.password = "";
			this.password_again = "";
			this.setShowChangePassword(false);
		},
		async change_password(){
			if(this.password == ''){
				this.$toast.warning(this.$t("message.password_can_not_be_empty"));
				return;
			}
			if(this.password != this.password_again){
				this.$toast.warning(this.$t("message.two_passwords_are_different"));
				return;
			}
			if(await this.$adminAPI.change_password(this.password)){
				this.$toast.success(this.$t("message.password_has_been_changed"));
				this.password = "";
				this.password_again = "";
				this.setShowChangePassword(false);
			}else{
				this.$toast.error(this.$t("message.failed_to_change_password"));
				return;
			}
		},
		...mapActions([
			'setShowChangePassword',
		]),
		async update_mail_address(){
			if(await this.$adminAPI.set_mail_address_for_user({
				mail_address: this.mail_address,
			})){
				this.$toast.success(this.$t("message.updated"));
				this.setShowChangePassword(false);
			}else{
				this.$toast.error(this.$t("message.failed"));
				return;
			}
		},
	},
};
</script>
