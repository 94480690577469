export default {
	install: (app) => {
		app.mixin({
			methods: {
				$show_loading(msg){
					this.$store.commit('setLoadingMessage', msg);
				},
				$hide_loading(){
					this.$store.commit('setLoadingMessage', null);
				},
			},
		});
	},
};
