import AdminAPI from '../api/admin';

async function data_fetch(definition){
	let result = null;
	if(definition.api){
		result = await AdminAPI[definition.api](...definition.params);
	}else if(definition.apiv2){
		result = await AdminAPI[definition.apiv2](definition.params);
	}
	if(definition.parser == 'raw'){
		// empty
	}else if(definition.parser == 'json'){
		result = JSON.parse(result);
	}
	if(definition.path){
		result = eval("result" + definition.path);
	}
	/*
	console.log("=======================");
	console.log(result);
	console.log("=======================");
	*/
	return result;
}

export {
	data_fetch,
}
