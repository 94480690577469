<template>
	<input
		v-model="value" :class="{
			'compact-input-text': true,
			'dark': theme == 'dark',
		}"
		type="text"
		@input="change_value"
		@change="change_value"
		:style="{
			'width': '' + width + 'px',
		}"
		:disabled="readOnly"
		:placeholder="placeholder"
	/>
</template>
<style>
input[type=text].compact-input-text {
	padding-left: 5px;
	border-style: solid;
	border-radius: 5px;
	border-color: #000;
	border-width: 1px;
	color: #000;
	background-color: #FFF;
	margin: 2px;
}
input[type=text].compact-input-text.dark {
	border-color: #FFF;
	color: #FFF;
	background-color: #000;
}
</style>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'CompactInputText',
	data () {
		return {
			value: this.modelValue,
		}
	},
	props: {
		readOnly: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: String,
			required: true,
		},
		width: {
			type: Number,
			default: 200,
		},
		placeholder: {
			type: String,
			default: "",
		},
	},
	emits: {
		"update:modelValue": null,
		"change": null,
	},
	computed: {
		...mapGetters([
			'theme',
		]),
	},
	components: {
	},
	methods: {
		change_value(){
			this.$emit('update:modelValue', this.value);
			this.$emit('change', this.value);
		},
	},
	async mounted(){
	},
}
</script>
