<template>
  <v-dialog v-model="isVisible" width="auto">
    <v-card width="800" prepend-icon="mdi-robot-outline" title="GPT Assistant">
      <v-card-text>
        <v-textarea v-model="inputValue" variant="outlined" :label="title"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn @click="confirm">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      title: '',
      inputValue: '',
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open(title) {
      this.title = title;
      this.isVisible = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.isVisible = false;
      this.resolve(this.inputValue);
      this.inputValue = "";
    },
    cancel() {
      this.isVisible = false;
      this.reject(new Error('User canceled'));
      this.inputValue = "";
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  margin: 10px 0;
  padding: 8px;
  width: 100%;
}

button {
  margin: 10px 5px;
  padding: 8px 15px;
  cursor: pointer;
}
</style>