<template>
	<v-icon icon="mdi-web"/>
	<v-autocomplete
		:items="domains"
		@update:modelValue="onChange"
		v-model="selected_domain"
		:label="$t('ui.domain')"
		:placeholder="$t('ui.select_domain')"
		@click="onClick"
	/>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'DomainSelector',
	data(){
		return {
			selected_domain: "",
			domains: [],
			domain_id_map: {},
		};
	},
	emits: {
		domain_changed: null,
	},
	computed: {
		...mapGetters([
			//'domains',
		]),
	},
	async mounted(){
		console.log('init domain selector');
		let domains = await this.$adminAPI.get_domains();
		//console.log("domains");
		console.log(domains);
		domains.forEach((d)=>{
			let show_name = d.domain;
			if(d.is_tenant){
				show_name = "[" + this.$t("ui.tenant") + "] " + d.domain_group_name;
			}else if(d.is_partner){
				show_name = "[" + this.$t("ui.partner") + "] " + d.domain_group_name;
			}else if(d.is_domain_group){
				show_name = "[" + this.$t("ui.domain_group") + "] " + d.domain_group_name;
			}
			d.show_name = show_name;
			this.domain_id_map[show_name] = d.id;
			if(d.selected){
				//console.log(d);
				this.selected_domain = show_name;
				this.setDomain({
					domain: d.domain,
					domain_id: d.id,
				});
			}
		});
		await this.load_domain_abilities();
		this.domains = domains.map((x)=>x.show_name);
		//console.log(this.domains);
	},
	methods: {
		...mapActions([
			'setDomain',
			'setDomainAbilities',
		]),
		async onChange(item){
			if(! item){
				return;
			}
			let domain_id = this.domain_id_map[item];
			await this.$adminAPI.select_domain(item, domain_id);
			this.$emit('domain_changed', this.domain_id);
			this.$toast.info(this.$t("message.change_to_domain") + ": " + item);
			this.setDomain({
				domain: item,
				domain_id,
			});
			await this.load_domain_abilities();
		},
		async load_domain_abilities(){
			let domain_abilities_str = await this.$adminAPI.get_domain_memory("domain_abilities", "");
			let domain_abilities = {};
			domain_abilities_str.split(',').forEach((one_ability)=>{
				if(one_ability != ''){
					domain_abilities[one_ability] = true;
				}
			});
			this.setDomainAbilities(domain_abilities);
		},
		async onClick(evt){
			evt.target.select();
		},
	},
};
</script>
