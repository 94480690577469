<template>
	<component :is="component_type" v-if="has_content"></component>
</template>
<script>
import { defineAsyncComponent } from "vue";

export default {
	name: 'CardContent',
	data(){
		return {
		};
	},
	props: {
		component_name: {
			type: String,
		},
	},
	computed: {
		has_content(){
			return this.component_name != '';
		},
		component_type(){
			return this.has_content ? defineAsyncComponent(() => import("./" + this.component_name)): '';
		},
	},
	methods: {
	},
};
</script>
