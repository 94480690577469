import { createStore } from 'vuex'

export default createStore({
	state: {
		theme: 'dark',
		masterMode: false,
		pageLevel: 'engine_flow',
		selectedEngine: '',
		selectedSubEngine: '',
		selectedPage: '',
		engineFlowMenu: null,
		subEngineFlowMenu: null,
		domainID: 0,
		domain: "",
		username: "",
		isSuperUser: false,
		role: "nobody",
		loggedIn: false,
		showDashBoard: false,
		domains: [],
		loadingMessage: null,
		topPage: null,
		showChangePassword: false,
		domainAbilities: {},
		userAttributes: {},
		pageLoading: false,
		showMenu: true,
	},
	getters: {
		theme: (state) => state.theme,
		pageLevel: (state) => state.pageLevel,
		selectedEngine: (state) => state.selectedEngine,
		selectedSubEngine: (state) => state.selectedSubEngine,
		selectedPage: (state) => state.selectedPage,
		domainID: (state) => state.domainID,
		domain: (state) => state.domain,
		username: (state) => state.username,
		isSuperUser: (state) => state.isSuperUser,
		showMenu: (state) => state.showMenu,
		showChangePassword: (state) => state.showChangePassword,
		loggedIn: (state) => state.loggedIn,
		showDashBoard: (state) => state.showDashBoard,
		domains: (state) => state.domains,
		loadingMessage: (state) => state.loadingMessage,
		topPage: (state) => state.topPage,
		masterMode: (state) => state.masterMode,
		domainAbilities: (state) => state.domainAbilities,
		userAttributes: (state) => state.userAttributes,
		pageLoading: (state) => state.pageLoading,
	},
	mutations: {
		setMasterMode(state, new_mode){
			console.log(`master mode => ${new_mode}`);
			state.masterMode = new_mode;
		},
		setPageLevel(state, new_page_level){
			console.log(`page level => ${new_page_level}`);
			state.pageLevel = new_page_level;
		},
		selectEngine(state, engine){
			state.selectedEngine = engine;
			//state.subEngineFlowMenu.engine_name = engine;
			state.subEngineFlowMenu.remount();
		},
		selectSubEngine(state, engine){
			state.selectedSubEngine = engine;
		},
		selectPage(state, page){
			state.selectedPage = page;
		},
		setLoggedIn(state, logged_in){
			state.loggedIn = logged_in;
		},
		setShowDashBoard(state, show_dash_board){
			state.showDashBoard = show_dash_board;
		},
		setShowChangePassword(state, showChangePassword){
			state.showChangePassword = showChangePassword;
		},
		setUsername(state, username){
			state.username = username;
		},
		setIsSuperUser(state, isSuperUser){
			state.isSuperUser = isSuperUser;
		},
		toggleShowMenu(state){
			state.showMenu = ! state.showMenu;
		},
		setDomains(state, domains){
			state.domains = domains;
		},
		setDomainAbilities(state, domain_abilities){
			state.domainAbilities = domain_abilities;
		},
		setUserAttributes(state, user_attributes){
			state.userAttributes = user_attributes;
		},
		setDomain(state, {
			domain,
			domain_id,
		}){
			state.domain = domain;
			state.domainID = domain_id;
		},
		setLoadingMessage(state, msg){
			state.loadingMessage = msg;
		},
		setTopPage(state, top_page){
			state.topPage = top_page;
		},
		setPageLoading(state, pageLoading){
			state.pageLoading = pageLoading;
		},
	},
	actions: {
		loadMasterMode({commit}){
			if(localStorage["master_mode"]){
				if(localStorage["master_mode"] == 'true'){
					commit('setMasterMode', true);
				}else{
					commit('setMasterMode', false);
				}
			}
		},
		toggleMasterMode({commit}){
			if(this.state.masterMode){
				localStorage['master_mode'] = "false";
				commit('setMasterMode', false);
			}else{
				localStorage['master_mode'] = "true";
				commit('setMasterMode', true);
			}
		},
		setPageLevel({commit}, newLevel){
			commit('setPageLevel', `${this.state.pageLevel}>${newLevel}`);
			setTimeout(()=>{
				commit('setPageLevel', newLevel);
			}, 200);
		},
		selectEngine({commit}, engine){
			commit('selectEngine', engine);
		},
		selectSubEngine({commit}, engine){
			commit('selectSubEngine', engine);
		},
		selectPage({commit}, page){
			commit('selectPage', page);
		},
		setLoggedIn({commit}, logged_in){
			commit('setLoggedIn', logged_in);
		},
		setShowDashBoard({commit}, show_dash_board){
			commit('setShowDashBoard', show_dash_board);
		},
		setDomains({commit}, domains){
			commit('setDomains', domains);
		},
		setDomainAbilities({commit}, domain_abilities){
			commit('setDomainAbilities', domain_abilities);
		},
		setUserAttributes({commit}, user_attributes){
			commit('setUserAttributes', user_attributes);
		},
		setUsername({commit}, username){
			commit('setUsername', username);
		},
		setIsSuperUser({commit}, isSuperUser){
			commit('setIsSuperUser', isSuperUser);
		},
		toggleShowMenu({commit}){
			commit('toggleShowMenu');
		},
		setShowChangePassword({commit}, showChangePassword){
			commit('setShowChangePassword', showChangePassword);
		},
		setDomain({commit}, {
			domain,
			domain_id,
		}){
			//console.log(domain);
			//console.log(domain_id);
			console.log(`setDomain: ${domain}(${domain_id})`);
			commit('setDomain', {
				domain,
				domain_id,
			});
		},
		setLoadingMessage({commit}, msg){
			commit('setLoadingMessage', msg);
		},
		setTopPage({commit}, top_page){
			console.log(`setTopPage`);
			console.log(top_page);
			commit('setTopPage', top_page);
		},
		setPageLoading({commit}, pageLoading){
			commit('setPageLoading', pageLoading);
		},
	},
	modules: {
	}
})
