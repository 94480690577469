<template>
	<tool-tip position="left" :tooltipText="$t('tooltip.ai_assistant')">
		<v-app-bar-nav-icon @click="handleShowAIDialog" variant="text" icon="mdi-robot-outline" ></v-app-bar-nav-icon>
	</tool-tip>
	<ai-dialog ref="prompt" />
</template>
<style>
</style>
<script>
import AiDialog from '../AiDialog.vue';

export default {
	name: 'AiAssistant',
	components: {
		AiDialog,
	},
	data () {
		return {
		}
	},
	props: {
	},
	emits: {
		inputText: null,
	},
	methods: {
		async handleShowAIDialog() {
			try
			{
				const response = await this.$refs.prompt.open(this.$t("ai.instruction.navigate_to_engine"));
				console.log('User entered:', response);
				this.$emit('inputText', response);
			} catch (error) {
				console.log('User canceled or prompt closed');
			}
		},
	},
	mounted(){
	},
};
</script>
