<template>
	<v-card>
		<v-card-title>{{ $t("ui.theme") }}</v-card-title>
		<v-card-text>
			<v-radio-group v-model="$store.state.theme" :hide-details="true" @change="theme_changed">
				<v-radio
					v-for="one_theme in themes"
					:key="one_theme"
					:label="$t('theme.' + one_theme)"
					:value="one_theme"
				></v-radio>
			</v-radio-group>
		</v-card-text>
	</v-card>
</template>
<style scoped>
.v-switch {
	display: inline-block;
}
</style>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ThemeSwitch',
	computed: {
		...mapGetters([
			'masterMode',
		]),
		themes(){
			if(this.masterMode){
				return [
					'light',
					'dark',
					'diablo',
					'gay',
				];
			}else{
				return [
					'light',
					'dark',
				];
			}
		},
	},
	methods: {
		theme_changed(){
			localStorage["saved_theme"] = this.$store.state.theme;
		},
	},
};
</script>

