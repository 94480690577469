function sleep(time){
	return new Promise((resolve) => {
		setTimeout(resolve, time);
	});
}

function get_column_value(row, column){
	let column_type = "simple";
	//console.log(row);
	//console.log(column);
	if(column.type){
		column_type = column.type;
	}
	if(column_type == "deep"){
		let true_column_name = column.name.split(".")[0];
		// eslint-disable-next-line
		let true_value = JSON.parse(row[true_column_name]);
		let deep_path = column.name.replace(/^[^.]+\./, '');
		return eval("true_value." + deep_path);
	}else{
		return row[column.name];
	}
}

export {
	sleep,
	get_column_value,
}
