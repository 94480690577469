import mytoast from "../plugins/mytoast";
import { sleep } from "../tools";
import { db } from "../db";

/*
import spam from "../configs/spam";
const configDefinitions = {
	spam,
};
*/

let apis = {
	/*
	...([
		"get_slaves",
	].reduce((funcs, name) => {
		funcs[name] = async (params) => {
			let result = await this.call(name, params);
			return result.success ? result.result : null;
		};
		return funcs;
	}, {})),
	*/
	async reset_password_by_username_mail_address(params){
		let result = await this.call("reset_password_by_username_mail_address", params, '/admin/wildapi/');
		if(result.success && result.result){
			return result.result;
		}else{
			return false;
		}
	},
	async get_custom_logo(){
		let result = await this.call("get_custom_logo", {}, '/admin/wildapi/');
		//console.log(result);
		if(result.success && result.result){
			return result.result;
		}else{
			return false;
		}
	},
	async is_logged_in(){
		let result = await this.call("is_logged_in", {}, '/admin/wildapi/');
		//console.log(result);
		if(result.success && result.result){
			return result.result;
		}else{
			return false;
		}
	},
	async logout(){
		let result = await this.call("logout", {}, '/admin/wildapi/');
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_lc(lc){
		let result = await this.call("get_lc", {
			lc,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_moe(params={}){
		let result = await this.call("get_moe", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async change_password(password){
		let result = await this.call("change_password", {
			password,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async check_2fa_login(username, password, totp){
		let result = await this.call("check_2fa_login", {
			username,
			password,
			totp
		}, '/admin/wildapi/');
		//console.log(result);
		if(result.success && result.result.login_ok){
			return result.result;
		}else{
			return null;
		}
	},
	async check_login(username, password){
		let result = await this.call("check_login", {
			username,
			password,
		}, '/admin/wildapi/');
		//console.log(result);
		if(result.success && result.result.login_ok){
			return result.result;
		}else{
			return null;
		}
	},
	async select_domain(domain, domain_id){
		let result = await this.call("select_domain", {
			domain,
			target_domain_id: domain_id,
		});
		return result.success ? true : false;
	},
	async get_license_info(){
		let result = await this.call("get_license_info", {});
		return result.success ? result.result : {};
	},
	async get_queue_list(queue_type, direction){
		let result = await this.call("get_queue_list", {
			queue_type,
			direction,
		});
		return result.success ? result.result : [];
	},
	async get_recent_list(table_name, target_column, search_condition, limit){
		let result = await this.call("get_recent_list", {
			table_name,
			target_column,
			search_condition,
			limit,
		});
		return result.success ? result.result : [];
	},
	async get_top_list(table_name, group_by, search_condition, limit){
		let result = await this.call("get_top_list", {
			table_name,
			group_by,
			search_condition,
			limit,
		}, "/admin/api/", 3);
		return result.success ? result.result : [];
	},
	async get_partner_users(){
		let result = await this.call("get_partner_users");
		return result.success ? result.result : [];
	},
	async get_all_users(){
		let result = await this.call("get_all_users");
		return result.success ? result.result : [];
	},
	async get_all_domains(){
		let result = await this.call("get_all_domains");
		return result.success ? result.result : [];
	},
	async get_child_domains(){
		let result = await this.call("get_child_domains");
		return result.success ? result.result : [];
	},
	async get_domains(){
		let result = await this.call("get_domains");
		return result.success ? result.result : [];
	},
	async get_connection_info(connection_id){
		let result = await this.call("get_connection_info", {
			connection_id,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_mail_urls(mail_id){
		let result = await this.call("get_mail_urls", {
			mail_id,
		});
		if(result.success){
			return result.result;
		}else{
			return [];
		}
	},
	async get_user_attributes(){
		let result = await this.call("get_user_attributes");
		if(result.success){
			return result.result;
		}else{
			return {};
		}
	},
	async get_mail_attachments(mail_id){
		let result = await this.call("get_mail_attachments", {
			mail_id,
		});
		if(result.success){
			return result.result;
		}else{
			return [];
		}
	},
	async get_tag_info(mail_id){
		let result = await this.call("get_tag_info", {
			mail_id,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_mail_info(mail_id){
		let result = await this.call("get_mail_info", {
			mail_id,
		});
		if(result.success && result.result.found){
			return result.result.mail_info;
		}else{
			return null;
		}
	},
	async get_mail_preview(mail_id){
		let result = await this.call("get_mail_preview", {
			mail_id,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_domain_deep_memory(name, key_name, default_value, domain_id=0){
		let result = await this.call("get_domain_deep_memory", {
			name,
			key_name,
			default_value,
			domain_id,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_user_attribute(user_id, name, default_value){
		let result = await this.call("get_user_attribute", {
			user_id,
			name,
			default_value,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async update_global_variable(name, value){
		let result = await this.call("update_global_variable", {
			name,
			value,
		});
		if(result.success){
			return true;
		}else{
			return null;
		}
	},
	async get_global_variable(name, default_value){
		let result = await this.call("get_global_variable", {
			name,
			default_value,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_domain_memory(name, default_value, domain_id=0){
		let result = await this.call("get_domain_memory", {
			name,
			default_value,
			domain_id,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_map_items(map_name, is_global){
		let result = await this.call("get_map_items", {
			map_name,
			is_global,
		});
		if(result.success){
			return result.result;
		}else{
			return {};
		}
	},
	async get_kids_rule_by_id(rule_id){
		let result = await this.call("get_kids_rule_by_id", {
			rule_id,
		});
		if(result.success){
			return result.result;
		}else{
			return "";
		}
	},
	async get_kids_rules(){
		let result = await this.call("get_kids_rules", {});
		if(result.success){
			return result.result;
		}else{
			return [];
		}
	},
	async add_custom_rule(rule_json, direction="inbound"){
		let result = await this.call("add_custom_rule", {
			rule_json,
			direction,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_custom_rules(direction){
		let result = await this.call("get_custom_rules", {
			direction,
		});
		if(result.success){
			return result.result;
		}else{
			return [];
		}
	},
	async get_mailbox_wb_list(color){
		let result = await this.call("get_mailbox_wb_list", {
			color,
		});
		if(result.success){
			return result.result;
		}else{
			return [];
		}
	},
	async get_list_values(list_name, is_global=false){
		let result = await this.call("get_list_values", {
			list_name,
			is_global,
		});
		if(result.success){
			return result.result;
		}else{
			return [];
		}
	},
	async remove_list_value(list_name, list_value, is_global=false){
		let result = await this.call("remove_list_value", {
			list_name,
			list_value,
			is_global,
		});
		return !!result.success;
	},
	async remove_map_key(map_name, key, is_global){
		let result = await this.call("remove_map_key", {
			map_name,
			key,
			is_global,
		});
		return !!result.success;
	},
	async add_map_item(map_name, key, value, is_global){
		let result = await this.call("add_map_item", {
			map_name,
			key,
			value,
			is_global,
		});
		return !!result.success;
	},
	async rename_kids_rule(rule_name, new_name){
		let result = await this.call("rename_kids_rule", {
			rule_name,
			new_name,
		});
		return !!result.success;
	},
	async update_domain_deep_memory(name, key_name, value, domain_id=0){
		let result = await this.call("update_domain_deep_memory", {
			name,
			key_name,
			value,
			domain_id,
		});
		return !!result.success;
	},
	async update_user_attribute(user_id, name, value){
		let result = await this.call("update_user_attribute", {
			user_id,
			name,
			value,
		});
		return !!result.success;
	},
	async update_domain_memory(name, value, domain_id=0){
		let result = await this.call("update_domain_memory", {
			name,
			value,
			domain_id,
		});
		return !!result.success;
	},
	async update_kids_rule(rule_name, workspace, code){
		let result = await this.call("update_kids_rule", {
			rule_name,
			workspace,
			code,
		});
		return !!result.success;
	},
	async remove_custom_rule(rule_id){
		let result = await this.call("remove_custom_rule", {
			rule_id,
		});
		return !!result.success;
	},
	async delete_mailbox_wb_list_value(address, entry){
		let result = await this.call("delete_mailbox_wb_list_value", {
			address,
			entry,
		});
		return !!result.success;
	},
	async delete_kids_rule(rule_name){
		let result = await this.call("delete_kids_rule", {
			rule_name,
		});
		return !!result.success;
	},
	async add_empty_kids_rule(new_rule_name){
		let result = await this.call("add_empty_kids_rule", {
			new_rule_name,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async add_list_value(list_name, list_value, is_global=false){
		let result = await this.call("add_list_value", {
			list_name,
			list_value,
			is_global,
		});
		return !!result.success;
	},
	async batch_update_list(list_name, list_values, is_global=false){
		let result = await this.call("batch_update_list", {
			list_name,
			list_values,
			is_global,
		});
		return !!result.success;
	},
	async update_mta_config(name, value, domain_id=0){
		let result = await this.call("update_mta_config", {
			name,
			value,
			domain_id,
		});
		return !!result.success;
	},
	async get_mta_config(name, domain_id=0){
		let result = await this.call("get_mta_config", {
			name,
			domain_id,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async update_config(config_name, config_value, inbound=true, domain_id=0){
		let result = await this.call("update_domain_config", {
			config_name,
			config_value,
			inbound,
			domain_id,
		});
		return !!result.success;
	},
	async get_config(config_name, inbound=true, domain_id=0){
		let result = await this.call("get_config", {
			config_name,
			inbound,
			domain_id,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_configs(configs, inbound=true){
		let config_names = configs.map((c)=>c.name);
		let result = await this.call("get_configs", {
			config_names,
			inbound
		});
		if(result.success){
			let nv_map = result.result;
			return configs.map((c)=>{
				return {
					name: c.name,
					type: c.type,
					value: nv_map[c.name],
				};
			});
		}else{
			return [];
		}
	},
	/*
	async get_slaves(){
		let result = await this.call("get_slaves", {});
		return result.success ? result.result : null;
	},
	*/
	async delete_slave(ip){
		let result = await this.call("delete_slave", {
			ip,
		});
		return result.success ? result.result : null;
	},
	async get_definition(name){
		//console.log(`get definition: ${name}`);
		let result = await this.call("get_definition", {
			name,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async report_fn(mail_id){
		//console.log(`report fn: ${mail_id}`);
		let result = await this.call("report_fn", {
			mail_id,
		});
		return result.success;
	},
	async report_fp(mail_id){
		//console.log(`report fp: ${mail_id}`);
		let result = await this.call("report_fp", {
			mail_id,
		});
		return result.success;
	},
	async retract_mail(mail_id, backend){
		//console.log(`retract mail: ${mail_id} (${backend})`);
		let result = await this.call("retract_mail", {
			mail_id,
			backend,
		});
		return result.success && result.result;
	},
	async replay_mail(mail_id){
		//console.log(`replay mail: ${mail_id}`);
		let result = await this.call("replay_mail", {
			mail_id,
		});
		return result.success;
	},
	async release_mail(mail_id){
		//console.log(`release mail: ${mail_id}`);
		let result = await this.call("release_mail", {
			mail_id,
		});
		return result.success;
	},
	async push_report_queue(definition){
		//console.log(`push report queue`);
		let result = await this.call("push_report_queue", {
			definition,
		});
		return result.success;
	},
	async get_2fa_image(){
		let result = await this.call("get_2fa_image", {});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async generate_dkim_key(){
		let result = await this.call("generate_dkim_key", {});
		if(result.success){
			return true;
		}else{
			return null;
		}
	},
	async get_mail_timeline(mail_id){
		//console.log(`get mail timeline: ${mail_id}`);
		let result = await this.call("get_mail_timeline", {
			mail_id,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_merged_log(params={}){
		//console.log(`get merged log: ${JSON.stringify(params)}`);
		let result = await this.call("get_merged_log", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_domain_status(){
		//console.log(`get domain status`);
		let result = await this.call("get_domain_status", {});
		if(result.success){
			return JSON.parse(result.result);
		}else{
			return null;
		}
	},
	async get_reject_log(params={}){
		//console.log(`get reject log: ${JSON.stringify(params)}`);
		let result = await this.call("get_reject_log", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async create_partner_domain(domain, model, subscribe_mailbox_count, account_name, expired_at){
		let result = await this.call("create_partner_domain", {
			domain,
			model,
			subscribe_mailbox_count,
			account_name,
			expired_at,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_security_events(params={}){
		//console.log(`get security events: ${JSON.stringify(params)}`);
		let result = await this.call("get_security_events", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_command_output(command){
		//console.log(`get command output: ${command}`);
		let result = await this.call("get_command_output", {command});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_partner_domains_status(){
		//console.log(`get partner domains status`);
		let result = await this.call("get_partner_domains_status", {});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_rows_from_query(params={}){
		let result = await this.call("get_rows_from_query", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_table_rows(params={}){
		//console.log(`get table rows: ${JSON.stringify(params)}`);
		let result = await this.call("get_table_rows", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_mail_log(params={}){
		//console.log(`get mail log: ${JSON.stringify(params)}`);
		let result = await this.call("get_mail_log", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_latest_time_for_table(params={}){
		//console.log(`latest time: ${JSON.stringify(params)}`);
		let result = await this.call("get_latest_time_for_table", params, '/admin/api/', 0, true);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async count_in_days_using_statistics(days, mail_type, silent=false, is_outbound=false){
		//console.log(`count in days using statistics: ${days} ${mail_type}`);
		let result = await this.call("count_in_days_using_statistics", {
			days,
			mail_type,
			is_outbound,
		}, "/admin/api/", 3, silent);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async count_in_days(params={}){
		//console.log(`count in days: ${JSON.stringify(params)}`);
		let result = await this.call("count_in_days", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_mail_count(params={}){
		//console.log(`get mail count: ${JSON.stringify(params)}`);
		let result = await this.call("get_mail_count", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async get_chart(params={}){
		//console.log(`get chart: ${name}`);
		//console.log(params);
		let result = await this.call("get_chart", params);
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async upload_common(file){
		let result = await this.upload('upload_common', file, {});
		return result.success ? result.result : null;
	},
	async import_mailbox_wb_list(color, file){
		let result = await this.upload('import_mailbox_wb_list', file, {
			color,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async import_map(map_name, file, is_global=false){
		let result = await this.upload('import_map', file, {
			map_name,
			is_global,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async import_list(list_name, file, is_global=false){
		let result = await this.upload('import_list', file, {
			list_name,
			is_global,
		});
		if(result.success){
			return result.result;
		}else{
			return null;
		}
	},
	async upload(func_name, file, params={}, url_path='/admin/upload/'){
		if(process.env.NODE_ENV == 'development'){
			console.log(`upload: ${func_name}`);
		}
		let submit_params = {};
		Object.keys(params).forEach((k) => {
			if(typeof params[k] == 'number'){
				submit_params[k] = params[k].toString();
			}else{
				submit_params[k] = params[k];
			}
		});
		try{
			let data = new FormData();
			data.append('func_name', func_name);
			data.append('file', file);
			Object.keys(submit_params).forEach((k) => {
				data.append(k, submit_params[k]);
			});
			let httpRequest = await fetch(url_path, {
				method: 'POST',
				cache: 'no-cache',
				body: data,
			});
			let result = await httpRequest.json();
			if(! result.success){
				mytoast.error(result.errormsg.toString());
			}
			if(process.env.NODE_ENV == 'development'){
				console.log(result);
			}
			return result;
		}catch(e){
			console.log(e);
			console.log(this);
			mytoast.error(e.toString());
			return {
				success: false,
				errormsg: e.toString(),
			};
		}
	},
	/*
	async easy_call(func_name, params={}){
		let result = await this.call(func_name, params);
		return result.success ? result.result : null;
	},
	*/
	async call(func_name, params={}, url_path='/admin/api/', retry=0, silent=false){
		/*
		await db.api_logs.add({
			ts: (new Date().toLocaleString()),
			api: func_name,
			detail: JSON.stringify(params),
		});
		*/
		if(process.env.NODE_ENV == 'development'){
			console.log(`call api: ${func_name}${silent ? ' (silent)' : ''}`);
		}
		let submit_params = {};
		Object.keys(params).forEach((k) => {
			if(typeof params[k] == 'number'){
				submit_params[k] = params[k].toString();
			}else{
				submit_params[k] = params[k];
			}
		});
		for(let i=0;i<=retry;i++){
			let response_text = "";
			try{
				let httpRequest = await fetch(url_path, {
					method: 'POST',
					cache: 'no-cache',
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						func_name,
						...submit_params
					}),
				});
				//let result = await httpRequest.json();
				response_text = await httpRequest.text();
				let result = JSON.parse(response_text);
				if(! result.success){
					await db.api_errors.add({
						ts: (new Date().toLocaleString()),
						api: func_name,
						detail: JSON.stringify(params),
						error_detail: JSON.stringify(result),
					});
					if(! silent){
						mytoast.error(result.errormsg.toString());
					}
				}
				if(process.env.NODE_ENV == 'development'){
					console.log(result);
				}
				return result;
			}catch(e){
				await db.api_errors.add({
					ts: (new Date().toLocaleString()),
					api: func_name,
					detail: JSON.stringify(params),
					error_detail: e.toString() + "|||" + response_text,
				});
				console.log(e);
				//console.log(this);
				if(retry > 0 && i < retry){
					console.log(`wait for 2 seconds and try again`);
					await sleep(2000);
					console.log(`retry: ${func_name}`);
				}else{
					if(! silent){
						mytoast.error(e.toString());
					}
					return {
						success: false,
						errormsg: e.toString(),
					};
				}
			}
		}
	},
};

const apis_proxy = new Proxy(apis, {
	get(target, name){
		if(name in target){
			return target[name];
		}else{
			return async (params={}) => {
				let result = await target.call(name, params);
				return result.success ? result.result : null;
			};
		}
	},
});

export default apis_proxy;
