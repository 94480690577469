<template>
	<tool-tip position="left" :tooltipText="
		$t('tooltip.license_info')
		+ (info.license_already_expired ? ' (' + $t('message.already_expired') + ')' : '')
		+ (info.license_about_to_expire ? ' (' + $t('message.about_to_expire') + ')' : '')
	">
		<v-app-bar-nav-icon @click="show" variant="text" icon="mdi-license" :class="{
			'license-icon': true,
			'already-expired': info.license_already_expired,
			'about-to-expire': info.license_about_to_expire,
		}"></v-app-bar-nav-icon>
	</tool-tip>
	<v-dialog
		v-model="dialog"
		id="license-info"
	>
		<v-card>
			<v-card-title>
				<span>
					{{ $t("ui.license_info") }}
				</span>
				<v-spacer></v-spacer>
				<v-btn icon="mdi-close" size="small" @click="dialog=false"></v-btn>
			</v-card-title>
			<v-card-text>
				<show-loading :loaded="loaded">
					<v-container>
						<v-row>
							<v-col class="license-info-label" sm="6">{{ $t("ui.expire_date") }}</v-col><v-col :class="{
								'license-info-value': true,
								'already-expired': info.license_already_expired,
								'about-to-expire': info.license_about_to_expire,
							}" sm="6">
								{{ info.expire_date }}
								(
								<span v-if="! info.license_already_expired">{{ $t("message.days_left", {days: info.days_before_expire}) }}</span>
								<span v-if="info.license_already_expired">{{ $t("message.already_expired") }}</span>
								)
							</v-col>
							<v-col class="license-info-label" sm="6">{{ $t("ui.license_mailbox_count") }}</v-col><v-col class="license-info-value" sm="6">{{ info.license_mailbox_count }}</v-col>
							<v-col class="license-info-label" sm="6">{{ $t("ui.active_mailbox_count") }}</v-col><v-col class="license-info-value" sm="6">{{ info.active_mailbox_count }}</v-col>
						</v-row>
					</v-container>
				</show-loading>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<style>
button.license-icon.already-expired {
	color: #F00;
}
button.license-icon.about-to-expire {
	color: #e79632;
}
div.license-info-value.already-expired {
	color: #F00;
}
div.license-info-value.about-to-expire {
	color: #e79632;
}
.license-info-label {
	font-weight: bold;
}
.license-info-value {
}
</style>
<script>
import ShowLoading from '../ShowLoading';

export default {
	name: 'LicenseInfo',
	components: {
		ShowLoading,
	},
	data () {
		return {
			dialog: false,
			loaded: false,
			info: {},
		}
	},
	props: {
	},
	methods: {
		async getLicenseInfo(){
			this.info = await this.$adminAPI.get_license_info();
			console.log(this.info);
			this.loaded = true;
		},
		async show(){
			this.dialog = true;
		},
	},
	async mounted(){
		await this.getLicenseInfo();
	},
};
</script>
