<template>
	<v-container :class="{
		minimized: mode != 'full',
	}">
		<v-row align="center" justify="center">
			<v-col cols="12" sm="12" v-for="engine in sub_engines" :key="engine.name">
				<one-sub-engine :info="engine" :mode="mode" />
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
.minimized {
	zoom: 0.7;
}
</style>
<script>
import AdminAPI from '../api/admin';
import OneSubEngine from './OneSubEngine.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'SubEngineFlow',
	components: {
		OneSubEngine,
	},
	data(){
		return {
			sub_engines: [],
		};
	},
	props: {
		mode: {
			type: String,
		},
		engine_name: {
			type: String,
		},
	},
	computed: {
		...mapGetters([
			'pageLevel',
			'selectedEngine',
			'selectedSubEngine',
		]),
	},
	async mounted(){
		if(this.mode == "menu"){
			this.$store.state.subEngineFlowMenu = this;
		}
		await this.remount();
	},
	methods: {
		async remount(){
			if(this.selectedEngine != ''){
				this.sub_engines = await AdminAPI.get_definition(`engines/${this.selectedEngine}`) || [];
				//this.sub_engines = await AdminAPI.get_definition(`engines/rbl`) || [];
			}
		},
	},
};
</script>
