<template>
	<v-dialog v-model="dialog" persistent>
		<template v-if="! forget_password">
			<v-card width="400">
				<v-card-title>
					{{ $t('ui.login') }}
				</v-card-title>
				<v-card-text>
					<v-form>
						<v-container fluid>
							<v-row justify="center" v-if="! need_2fa">
								<v-col cols="12" sm="12" >
									<v-text-field
										v-model="username"
										prepend-icon="mdi-account"
										:rules="[rules.required]"
										type="text"
										:label="$t('ui.username')"
										:hint="$t('message.enter_username')"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="12" >
									<v-text-field
										v-model="password"
										prepend-icon="mdi-lock"
										:append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
										:rules="[rules.required]"
										:type="show_password ? 'text' : 'password'"
										:label="$t('ui.password')"
										:hint="$t('message.enter_password')"
										@click:append="show_password = !show_password"
										@keydown.enter.prevent="login"
									></v-text-field>
								</v-col>
								<v-progress-linear v-show="loading" indeterminate rounded height="6" ></v-progress-linear>
								<v-col cols="12" sm="4" >
									<v-btn @click="login">
										{{ $t("ui.login") }}
									</v-btn>
								</v-col>
							</v-row>
							<v-row justify="center" v-if="need_2fa">
								<v-col cols="12" sm="12" >
									<v-text-field
										v-model="totp"
										prepend-icon="mdi-onepassword"
										:rules="[rules.required]"
										type="text"
										:label="$t('ui.totp')"
										:hint="$t('message.enter_totp')"
										@keydown.enter.prevent="login_2fa"
									></v-text-field>
								</v-col>
								<v-progress-linear v-show="loading" indeterminate rounded height="6" ></v-progress-linear>
								<v-col cols="12" sm="4" >
									<v-btn @click="login_2fa">
										{{ $t("ui.login") }}
									</v-btn>
								</v-col>
							</v-row>
							<br/>
							<v-row justify="center">
								<span @click="forget_password = true;" class="link">{{ $t("ui.forget_password") }}</span>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
			</v-card>
		</template>
		<template v-if="forget_password">
			<v-card width="400">
				<v-card-title>
					{{ $t('ui.reset_password') }}
				</v-card-title>
				<v-card-text>
					<v-form>
						<v-container fluid>
							<v-row justify="center">
								<v-col cols="12" sm="12" >
									<v-text-field
										v-model="username"
										prepend-icon="mdi-account"
										:rules="[rules.required]"
										type="text"
										:label="$t('ui.username')"
										:hint="$t('message.enter_username')"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="12" >
									<v-text-field
										v-model="mail_address"
										prepend-icon="mdi-email"
										:rules="[rules.required]"
										type="text"
										:label="$t('ui.mail_address')"
										:hint="$t('message.enter_mail_address')"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="4" >
									<v-btn @click="reset_password">
										{{ $t("ui.reset_password") }}
									</v-btn>
								</v-col>
								<v-col cols="12" sm="4" >
									<v-btn @click="forget_password = false;">
										{{ $t("ui.cancel") }}
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
			</v-card>
		</template>
	</v-dialog>
</template>
<style>
span.link {
	text-decoration: underline;
}
span.link:hover {
	cursor: pointer;
}
</style>
<script>
import { mapActions } from 'vuex';
//import { sleep } from "../tools";

export default {
	name: 'LoginBox',
	components: {
	},
	data () {
		return {
			dialog: true,
			totp: '',
			username: '',
			password: '',
			show_password: false,
			loading: false,
			need_2fa: false,
			forget_password: false,
		}
	},
	computed: {
		rules(){
			return {
				required: value => !!value || this.$t('message.need_value'),
			};
		},
	},
	mounted(){
		console.log('login box');
	},
	methods: {
		async login(){
			this.loading = true;
			console.log("login");
			//await sleep(2000);
			let login_result = await this.$adminAPI.check_login(this.username, this.password);
			if(login_result && login_result.need_2fa){
				this.need_2fa = true;
				this.loading = false;
				return;
			}
			if(login_result){
				this.loading = false;
				this.succeed_login(login_result);
			}else{
				this.loading = false;
				this.$toast.warning(this.$t("message.login_fail"));
			}
		},
		async succeed_login(login_result){
			this.$toast.success(this.$t("message.login_success"));
			this.setLoggedIn(true);
			this.setUsername(this.username);
			this.setIsSuperUser(login_result.is_superuser);
			let user_attributes = await this.$adminAPI.get_user_attributes();
			this.setUserAttributes(user_attributes);
		},
		async login_2fa(){
			this.loading = true;
			let login_result = await this.$adminAPI.check_2fa_login(this.username, this.password, this.totp);
			if(login_result){
				this.loading = false;
				this.succeed_login(login_result);
			}else{
				this.loading = false;
				this.$toast.warning(this.$t("message.login_fail"));
			}
		},
		async reset_password(){
			this.loading = true;
			let result = await this.$adminAPI.reset_password_by_username_mail_address({
				username: this.username,
				mail_address: this.mail_address,
			});
			//console.log(result);
			if(result.error){
				this.$toast.error(this.$t('message.' + result.msg));
			}else{
				this.$toast.success(this.$t('message.' + result.msg));
			}
			this.loading = false;
			this.forget_password = false;
		},
		...mapActions([
			'setLoggedIn',
			'setDomains',
			'setUsername',
			'setIsSuperUser',
			'setUserAttributes',
		]),
	},
};
</script>
