<template>
	<v-slide-y-transition>
		<div id="top-loading" v-if="message">
			<v-card id="loading-content">
				<v-card-text>
					<v-progress-circular
						:size="100"
						color="primary"
						indeterminate
					/>
					<div class="message">{{ message }}</div>
				</v-card-text>
			</v-card>
		</div>
	</v-slide-y-transition>
</template>
<style scoped>
#top-loading {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 10000;
	background-color: #80808080;
}
#loading-content {
	margin: auto;
	margin: auto;
	width: 40%;
	margin-top: 200px;
	padding: 30px;
	text-align: center;
}
.message {
	font-size: 30px;
	margin-top: 20px;
}
</style>
<script>
export default {
	name: 'TopLoading',
	props: {
		message: {
			type: String,
		},
	},
	computed: {
	},
	methods: {
	},
};
</script>
