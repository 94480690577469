import en from './en';
import cn from './cn';
import cn_s from './cn_s';
import cn_t from './cn_t';

import tag_translation_en from './tag_translation_en';
en.tag = tag_translation_en;

export default {
	en,
	cn,
	cn_s,
	cn_t,
};
