<template>
	<v-card>
		<v-card-title>{{ $t("ui.language") }}</v-card-title>
		<v-card-text>
			<v-radio-group v-model="$i18n.locale" :hide-details="true" @change="lang_changed">
				<v-radio
					v-for="lang in langs"
					:key="lang.value"
					:label="lang.text"
					:value="lang.value"
				></v-radio>
			</v-radio-group>
		</v-card-text>
	</v-card>
</template>
<style scoped>
</style>
<script>
export default {
	name: 'LangSwitch',
	computed: {
		langs(){
			return [
				{
					value: 'en',
					text: 'English',
				},
				/*
				{
					value: 'cn',
					text: '中文',
				},
				*/
				{
					value: 'cn_s',
					text: '简体中文',
				},
				{
					value: 'cn_t',
					text: '繁体中文',
				},
			];
		},
	},
	methods: {
		lang_changed(){
			localStorage["saved_lang"] = this.$i18n.locale;
		},
	},
};
</script>

