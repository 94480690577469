import { useToast } from "vue-toastification";

const toast = useToast();
const toastDefaultConfig = {
	position: "top-left",
	timeout: 3009,
	closeOnClick: false,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: false,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: "button",
	icon: true,
	rtl: false,
};

const my_toast = Object.assign({}, ...[
	'success',
	'error',
	'warning',
	'info',
].map((f)=>{
	return {
		[f]: (msg) => {
			toast[f](msg, toastDefaultConfig);
		},
	};
}));

export default my_toast;
