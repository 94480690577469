<template>
	<v-container :class="{
		minimized: mode != 'full',
	}">
		<v-row align="center" justify="center">
			<template v-for="(engine, index) in engines" :key="index">
				<v-col cols="12" sm="12" v-if="should_display(index)">
					<one-engine :info="engine" :mode="mode" />
				</v-col>
			</template>
		</v-row>
	</v-container>
</template>
<style scoped>
.minimized {
	zoom: 0.7;
}
</style>
<script>
import AdminAPI from '../api/admin';
import OneEngine from './OneEngine.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'EngineFlow',
	components: {
		OneEngine,
	},
	data(){
		return {
			engines: [],
		};
	},
	props: {
		mode: {
			type: String,
		},
		num: {
			type: Number,
		},
	},
	computed: {
		...mapGetters([
			'pageLevel',
		]),
	},
	methods: {
		should_display(index){
			if(this.mode != 'full'){
				return true;
			}
			return (index % 3 == this.num);
		},
	},
	async mounted(){
		if(this.mode == "menu"){
			this.$store.state.engineFlowMenu = this;
		}
		this.engines = await AdminAPI.get_definition("engines") || [];
	},
};
</script>
