// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
//import colors from 'vuetify/lib/util/colors'

export default createVuetify(
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
	{
		theme: {
			defaultTheme: 'dark',
			themes: {
				light: {
					colors: {
						background: '#F1F6FD', // important
					},
				},
				gay: {
					colors: {
						surface: '#4b4cfb', // important
						background: '#FFFF00', // important
						"on-surface": '#ff5151', // important
						"on-primary": '#00FF00',
						primary: '#00FF00',
						secondary: '#0000FF',
						accent: '#FF00FF',
						info: '#CC0088',
					},
				},
				diablo: {
					colors: {
						surface: '#5c0000', // important
						background: '#000000', // important
						"on-surface": '#00b939', // important
					},
				},
			},
		},
	}
)
