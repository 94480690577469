import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import 'animate.css';
import messages from './i18n';
import colors from './plugins/colors';
import loading from './plugins/loading';
import helper from './plugins/helper';
import Toast from "vue-toastification";
//import VueMeta from 'vue-meta';
//import vuePugPlugin from 'vue-pug-plugin';
import "vue-toastification/dist/index.css";
import "./css/global.scss";
//import "vue-select/dist/vue-select.css";
import directives from "./directives/";
//import { VTooltip } from 'v-tooltip';
import ToolTip from "./components/ToolTip";
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
//import { VSelect } from "vue-select";
import { Table, ConfigProvider } from 'ant-design-vue';

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

const toast_options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
};

loadFonts()

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(colors)
  .use(loading)
  .use(helper)
  .use(VueFlatPickr)
  //.use(vuePugPlugin)
  .use(Toast, toast_options)
  .use(Table)
  .use(ConfigProvider);

directives(app);
app.component("tool-tip", ToolTip);

import CompactInputText from './components/CompactInputText';
app.component("c-text", CompactInputText);
import CompactSelect from './components/CompactSelect';
app.component("c-select", CompactSelect);

app.mount('#app');
