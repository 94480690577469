import colors from '../colors';

export default {
	install: (app) => {
		app.mixin({
			methods: {
				$color(zone, color){
					//console.log(colors);
					//console.log(zone);
					//console.log(color);
					return colors[zone][color];
				},
			},
		});
	},
};
