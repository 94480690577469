<template>
	<v-app id="app" :theme="theme" fill-height>
		<v-app-bar app>
			<v-app-bar-title class="logo-container">
				<template v-if="use_custom_logo">
					<img :src="custom_logo_url_path" class="logo-image" />
				</template>
				<template v-if="!use_custom_logo">
					<a href="javascript:void(0);" class="logo" @click="logo_clicked" style="display: none;"></a>
					<span class="logo-text" style="display: none;">sorb security</span>
				</template>
			</v-app-bar-title>
			<v-spacer></v-spacer>
			<domain-selector v-if="loggedIn" @domain_changed="domain_changed" />
			<!--<v-btn @click="jump_to_engine_by_keyword('custom policy')">Test</v-btn>-->
			<v-spacer></v-spacer>
			<template v-if="loggedIn">
				<tool-tip position="left" :tooltipText="$t('tooltip.home')">
					<v-app-bar-nav-icon @click="setPageLevel('engine_flow')" variant="text" icon="mdi-home"></v-app-bar-nav-icon>
				</tool-tip>
				<ai-assistant @inputText="jump_to_engine_by_keyword" />
				<license-info :key="domain" v-if="cloud_mode" />
				<license-info-on-prem :key="domain" v-if="!cloud_mode" />
				<user-notification-icon v-if="use_notification" />
				<!--
				<tool-tip position="left" :tooltipText="$t('tooltip.dashboard')">
					<v-app-bar-nav-icon @click="setShowDashBoard(true)" variant="text" icon="mdi-chart-areaspline"></v-app-bar-nav-icon>
				</tool-tip>
				-->
				<tool-tip position="left" :tooltipText="$t('tooltip.login_user') + ': ' + username">
					<v-app-bar-nav-icon @click="change_password" variant="text" icon="mdi-account"></v-app-bar-nav-icon>
				</tool-tip>
				<tool-tip position="left" :tooltipText="$t('tooltip.logout')">
					<v-app-bar-nav-icon @click="logout" variant="text" icon="mdi-logout"></v-app-bar-nav-icon>
				</tool-tip>
				<tool-tip position="left" :tooltipText="$t('tooltip.toggle_menu')">
					<v-app-bar-nav-icon @click="toggle_menu" variant="text" icon="mdi-page-layout-sidebar-left"></v-app-bar-nav-icon>
				</tool-tip>
			</template>
			<tool-tip position="left" :tooltipText="$t('tooltip.menu')">
				<v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			</tool-tip>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" position="right" temporary bottom>
			<v-container>
				<v-row>
					<v-col sm="12">
						<theme-switch />
					</v-col>
					<v-col sm="12">
						<lang-switch />
					</v-col>
				</v-row>
			</v-container>
		</v-navigation-drawer>
		<login-box v-if="!loggedIn" />
		<change-password v-if="showChangePassword" />
		<top-loading :message="loadingMessage" />
		<top-page :page="topPage" />
		<dash-board v-if="loggedIn && showDashBoard" />
		<v-main fill-height v-if="loggedIn" :style="pageLoading ? 'position:fixed;' : ''">
			<v-container fill-height class="main-container">
				<v-row>
					<v-col cols="12" :sm="focus_mode ? 2 : 4" :class="{ left_pane: true, focus_mode: focus_mode, hide: ! needShowMenu, }">
						<v-row>
							<v-slide-x-transition>
								<v-col cols="12" sm="12" elevation="1" v-show="pageLevel == 'engine_flow'" id="left-full-engine-flow" :key="domain">
									<engine-flow :mode="'full'" :num="0" />
								</v-col>
							</v-slide-x-transition>
							<v-col cols="12" sm="6" elevation="1" :key="domain">
								<v-slide-x-transition>
									<engine-flow :mode="'menu'" v-show="!pageLevel.match(/\bengine_flow$/)" />
								</v-slide-x-transition>
							</v-col>
							<v-col cols="12" sm="6" elevation="1" :key="domain">
								<v-slide-x-transition>
									<sub-engine-flow :mode="'menu'" v-show="pageLevel.match(/\bdetail$/)" />
								</v-slide-x-transition>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" :sm="needShowMenu ? (focus_mode ? 10 : 8) : 12" elevation="1">
						<v-container :key="domain" class="main-zone">
							<v-row>
								<v-slide-y-transition>
									<v-col sm="6" v-show="pageLevel == 'engine_flow'">
										<engine-flow :mode="'full'" :num="1" />
									</v-col>
								</v-slide-y-transition>
								<v-slide-x-reverse-transition>
									<v-col sm="6" v-show="pageLevel == 'engine_flow'">
										<engine-flow :mode="'full'" :num="2" />
									</v-col>
								</v-slide-x-reverse-transition>
								<v-slide-x-reverse-transition>
									<v-col sm="6" v-if="pageLevel == 'sub_engine_flow'">
										<sub-engine-flow :mode="'full'" />
									</v-col>
								</v-slide-x-reverse-transition>
								<v-slide-x-reverse-transition>
									<v-col sm="12" v-if="pageLevel == 'detail'">
										<card-content :component_name="selectedPage" v-if='typeof (selectedPage) == "string"'></card-content>
										<one-page :definition="selectedPage" v-if='typeof (selectedPage) == "object"'></one-page>
										<!--
										<v-card>
											<v-card-title>
												Detail Page of {{ selectedEngine }}/{{ selectedSubEngine }}
											</v-card-title>
											<v-card-text class="center">
												<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
											</v-card-text>
										</v-card>
										-->
									</v-col>
								</v-slide-x-reverse-transition>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
		<v-footer app>
			<v-card class="text-center" width="100%">
				<v-card-text>
					{{ new Date().getFullYear() }} - <strong>Sorb Security</strong>
				</v-card-text>
			</v-card>
		</v-footer>
		<v-dialog v-model="confirm_box" persistent>
			<v-card>
				<v-card-text>{{ confirm_box_message }}</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn @click="confirm_box_cancel">{{ $t("ui.cancel") }}</v-btn>
					<v-btn @click="confirm_box_confirm">{{ $t("ui.confirm") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
	<div v-if="pageLoading" class="page-loading-mask">
		<v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
	</div>
</template>
<style lang="scss">
//@import "./css/global.scss";

div.v-container.main-zone {
	max-width: none;
}
img.logo-image {
	height: 56px;
}

.left_pane.hide {
	display: none;
}

.left_pane.focus_mode {
	zoom: 0.5;
}

footer.v-footer {
	max-height: 70px;
}

.center {
	text-align: center;
}

[clickable] {
	cursor: pointer;
}

.v-container.main-container {
	margin: 0px;
	max-width: 3000px;
}

#left-full-engine-flow {
	margin-top: 16px;
}

.page-loading-mask {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
<script>
import EngineFlow from './components/EngineFlow.vue';
import SubEngineFlow from './components/SubEngineFlow.vue';
import ThemeSwitch from './components/ThemeSwitch.vue';
import LangSwitch from './components/LangSwitch.vue';
import { mapGetters, mapActions } from 'vuex';
import CardContent from './components/CardContent.vue';
import OnePage from './components/OnePage.vue';
import LoginBox from './components/LoginBox.vue';
import ChangePassword from './components/ChangePassword.vue';
import DashBoard from './components/DashBoard.vue';
import DomainSelector from './components/DomainSelector.vue';
import TopLoading from './components/TopLoading.vue';
import TopPage from './components/TopPage.vue';
import LicenseInfo from './components/pages/LicenseInfo.vue';
import LicenseInfoOnPrem from './components/pages/LicenseInfoOnPrem.vue';
import UserNotificationIcon from './components/pages/UserNotificationIcon.vue';
import AiAssistant from './components/pages/AiAssistant.vue';
import { defineAsyncComponent } from "vue";
//import { ref } from 'vue';

export default {
	name: 'App',
	components: {
		EngineFlow,
		SubEngineFlow,
		ThemeSwitch,
		LangSwitch,
		CardContent,
		LoginBox,
		ChangePassword,
		DashBoard,
		DomainSelector,
		OnePage,
		TopLoading,
		TopPage,
		LicenseInfo,
		LicenseInfoOnPrem,
		UserNotificationIcon,
		AiAssistant,
	},
	computed: {
		...mapGetters([
			'theme',
			'pageLevel',
			'selectedEngine',
			'selectedSubEngine',
			'selectedPage',
			'loggedIn',
			'showDashBoard',
			'domain',
			'loadingMessage',
			'topPage',
			'username',
			'showChangePassword',
			'pageLoading',
			'showMenu',
		]),
		needShowMenu(){
			if(this.pageLevel == "detail" && ! this.showMenu){
				return false;
			}
			return true;
		},
		window_height() {
			return window.innerHeight;
		},
		focus_mode() {
			if (this.pageLevel == "detail")
			{
				if (this.selectedSubEngine)
				{
					if ([
						"mail_query",
						"global_mail_query",
						"delivery_trace",
						"threat_review",
					].includes(this.selectedSubEngine)){
						return true;
					}
				}
			}
			return false;
		},
	},
	/*
	setup(){
		return {
			drawer: true,
		};
	},
	*/
	data() {
		return {
			drawer: true,
			logo_click_count: 0,
			use_custom_logo: false,
			custom_logo_url_path: "",
			use_notification: false,
			cloud_mode: false,
			confirm_box: false,
			confirm_box_title: "",
			confirm_box_message: "",
			confirm_box_cb: {},
		};
	},
	methods: {
		get_page_by_name(name) {
			return defineAsyncComponent(() => import("./components/pages/" + name));
		},
		...mapActions([
			'setUsername',
			'setIsSuperUser',
			'setUserAttributes',
			'setLoggedIn',
			'setShowDashBoard',
			'toggleMasterMode',
			'loadMasterMode',
			'setShowChangePassword',
			'setPageLevel',
			'selectEngine',
			'selectSubEngine',
			'selectPage',
			'setPageLoading',
			'toggleShowMenu',
		]),
		async logout() {
			console.log("logout");
			await this.$adminAPI.logout();
			this.$toast.success(this.$t("message.logout_success"));
			this.setLoggedIn(false);
			this.setPageLevel('engine_flow');
		},
		async load_saved_theme() {
			if (localStorage['saved_theme'])
			{
				this.$store.state.theme = localStorage['saved_theme'];
			}
			if (localStorage['saved_lang'])
			{
				this.$i18n.locale = localStorage['saved_lang'];
			}
		},
		async toggle_menu(){
			if(this.pageLevel != 'detail'){
				return;
			}
			this.toggleShowMenu();
			//console.log(`toggle menu: ${this.showMenu}`);
		},
		logo_clicked() {
			this.logo_click_count++;
			if (this.logo_click_count >= 10)
			{
				this.logo_click_count = 0;
				if (this.$store.state.masterMode)
				{
					this.$toast.info(this.$t("message.leave_master_mode"));
				} else
				{
					this.$toast.info(this.$t("message.enter_master_mode"));
				}
				this.toggleMasterMode();
			}
		},
		change_password() {
			this.setShowChangePassword(true);
		},
		domain_changed() {
			this.setPageLevel('engine_flow');
		},
		async jump_to_engine_by_keyword(desc) {
			this.setPageLoading(true);
			let result = await this.$adminAPI.get_engine_path_by_description({
				desc,
			});
			console.log(result);
			if (result.found)
			{
				this.selectEngine(result.engine);
				this.setPageLevel('sub_engine_flow');
				await this.$sleep(2000);
				let sub_engine = document.getElementById("subengine-" + result.sub_engine);
				sub_engine.click();
			}
			this.setPageLoading(false);
		},
		async confirm(message){
			this.confirm_box_message = message;
			this.confirm_box = true;
			let result = await new Promise((resolve)=>{
				this.confirm_box_cb = resolve;
			});
			this.confirm_box = false;
			return result;
		},
		confirm_box_cancel(){
			this.confirm_box_cb(false);
		},
		confirm_box_confirm(){
			this.confirm_box_cb(true);
		},
	},
	async mounted() {
		//console.log("mounted");
		let result = await this.$adminAPI.get_custom_logo();
		//console.log(result);
		if (result.has_custom_logo)
		{
			this.use_custom_logo = true;
			this.custom_logo_url_path = result.custom_logo_url_path;
		} else
		{
			document.querySelector('a.logo').style.display = "table-cell";
			document.querySelector('span.logo-text').style.display = "table-cell";
		}
		/*
		window.history.pushState(null, null, window.location.href);
		window.onpopstate = function () {
			window.history.go(1);
		};
		*/
		//window.onbeforeunload = function () { return "no back" };
		await this.load_saved_theme();
		this.loadMasterMode();
		document.title = "Sorb Security";
		document.querySelector('.v-application__wrap').style.minHeight = "" + this.window_height + "px";
		let user_info = await this.$adminAPI.is_logged_in();
		if (user_info)
		{
			//console.log(user_info);
			let { username, is_superuser } = user_info;
			console.log("<<<" + username + ">>>");
			console.log(is_superuser);
			this.$toast.success(this.$t("message.already_logged_in"));
			this.setLoggedIn(true);
			this.setUsername(username);
			this.setIsSuperUser(is_superuser);
			if (is_superuser)
			{
				console.log("SUPERUSER");
			}
			let user_attributes = await this.$adminAPI.get_user_attributes();
			this.use_notification = (await this.$adminAPI.get_lc('nexus.enable_hermes')) == 'yes';
			this.cloud_mode = (await this.$adminAPI.get_lc('nexus.cloud_mode')) == 'yes';
			console.log("user attributes");
			console.log(user_attributes);
			this.setUserAttributes(user_attributes);
		}
	},
}
</script>
