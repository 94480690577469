<template>
	<v-card>
		<show-loading :loaded="loaded">
			<v-table>
				<tbody>
					<tr>
						<th>&nbsp;</th>
						<th>{{ $t("ui.time") }}</th>
						<th v-if="admin">{{ $t("ui.user") }}</th>
						<th>{{ $t("ui.subject") }}</th>
					</tr>
					<template v-for="(one_message, index) in messages" :key="index">
						<tr class="one-message" @click="toggle_expand(one_message)">
							<td><span class="unread-mark" v-if="one_message.read == 0">●</span></td>
							<td>{{ one_message.create_time }}</td>
							<td v-if="admin">{{ one_message.recipient }}</td>
							<td>{{ one_message.subject }}</td>
						</tr>
						<tr v-if="one_message.expanded">
							<td :colspan="admin ? 4 : 3">
								<v-card>
									<v-card-text>
										<pre class="body-text">{{ one_message.body }}</pre>
									</v-card-text>
								</v-card>
							</td>
						</tr>
					</template>
				</tbody>
			</v-table>
		</show-loading>
	</v-card>
</template>
<style>
pre.body-text {
	word-wrap: break-word;
	white-space: break-spaces;
}
.unread-mark {
	color: #cf6679;
}
.one-message {
	cursor: pointer;
}
</style>
<script>
import { mapGetters } from 'vuex';
import ShowLoading from '../ShowLoading';

export default {
	name: 'UserNotificationList',
	data () {
		return {
			loaded: false,
			messages: [],
		}
	},
	props: {
		admin: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters([
			'username',
		]),
	},
	components: {
		ShowLoading,
	},
	emits: {
		change_unread: null,
	},
	methods: {
		async load_messages(){
			let messages = [];
			if(this.admin){
				messages = (await this.$adminAPI.get_notifications_sent_by_me()).messages;
			}else{
				messages = (await this.$adminAPI.get_user_notifications()).messages;
			}
			console.log(messages);
			messages.forEach((msg)=>{
				msg.expanded = false;
			});
			this.messages = messages;
		},
		async toggle_expand(one_message){
			one_message.expanded = ! one_message.expanded;
			if(one_message.expanded){
				if(!this.admin){
					one_message.read = 1;
					await this.$adminAPI.mark_user_notification_as_read({
						id: one_message.id,
					});
					this.$emit('change_unread', {});
				}
			}
		},
	},
	async mounted(){
		this.loaded = false;
		await this.load_messages();
		this.loaded = true;
	},
}
</script>
