<template>
	<v-dialog v-model="display" @click:outside="outside" width="90%" content-class="dashboard-dialog-content">
		<v-card>
			<v-row>
				<v-col cols="12" :sm="3 * (pane.colspan || 1)" elevation="1"
					v-for="pane in panes"
					:key="pane.name"
				>
					<v-slide-x-transition>
						<v-card :height="pane_height">
							<v-card-title>{{ $t("dashboard." + pane.name) }}</v-card-title>
							<v-card-text>
								<one-page :definition="pane.page"></one-page>
							</v-card-text>
						</v-card>
					</v-slide-x-transition>
				</v-col>
			</v-row>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OnePage from "./OnePage";

export default {
	name: 'DashBoard',
	data(){
		return {
			panes: [],
			display: true,
		};
	},
	components: {
		OnePage,
	},
	computed: {
		...mapGetters([
		]),
		pane_height(){
			return parseInt((window.innerWidth * 0.9 / 4) * 0.9);
		},
	},
	methods: {
		...mapActions([
			"setShowDashBoard",
		]),
		outside(){
			this.setShowDashBoard(false);
		},
	},
	async mounted(){
		this.panes = await this.$adminAPI.get_definition("dashboard") || [];
	},
};
</script>

