<template>
	<v-card :class="{
		selected: is_selected,
		'not-selected': ! is_selected,
		'can-click': can_click,
		'is-menu': mode == 'menu',
	}" @click="select_me()" :id="'subengine-' + info.name">
		<v-card-title>{{ info.display_name ? info.display_name : $t("engine.name." + info.name) }}</v-card-title>
		<v-card-subtitle v-if="mode == 'full' && $te('engine.description.' + info.name)">{{ $t("engine.description." + info.name) }}</v-card-subtitle>
		<v-card-text v-if="mode == 'full'">
			<one-page v-if="info.summary_page" :definition="info.summary_page"></one-page>
		</v-card-text>
		<!--
		<v-card-actions v-if="mode == 'full'">
			<v-btn @click="expand()">Expand</v-btn>
		</v-card-actions>
		-->
	</v-card>
</template>
<style lang="scss" scoped>
@import "../css/menu.scss";
</style>
<script>
//import CardContent from './CardContent.vue';
import { mapGetters, mapActions } from 'vuex';
import OnePage from "./OnePage";
//import ShowLoading from './ShowLoading';
//import { sleep } from '../tools';

export default {
	name: 'OneSubEngine',
	data(){
		return {
		};
	},
	props: {
		info: {
			type: Object,
		},
		mode: {
			type: String,
		},
	},
	components: {
		//ShowLoading,
		//CardContent,
		OnePage,
	},
	computed: {
		...mapGetters([
			'selectedEngine',
			'selectedSubEngine',
		]),
		is_selected(){
			if(this.mode == 'full'){
				return false;
			}
			if(this.info.name != this.selectedSubEngine){
				return false;
			}
			return true;
		},
		can_click(){
			if(this.mode != 'menu'){
				return true;
			}
			if(this.is_selected){
				return false;
			}
			return true;
		},
	},
	mounted(){
		if(this.mode == "menu"){
			return;
		}
	},
	methods: {
		...mapActions([
			'setPageLevel',
			'selectEngine',
			'selectSubEngine',
			'selectPage',
		]),
		expand(){
			console.log(this.info);
			this.selectSubEngine(this.info.name);
			if(this.info.page){
				this.selectPage(this.info.page);
			}else{
				this.selectPage("pages/NotImplemented");
			}
			this.setPageLevel("detail");
		},
		select_me(){
			if(this.can_click){
				this.expand();
			}
		},
	},
};
</script>
