<template>
	<select :class="{
		'compact-select': true,
		'dark': theme == 'dark',
	}" @change="change_value" v-model="value" :disabled="readOnly" >
	<option v-for="one_option in options" :key="one_option" :value="one_option">{{ (translate_prefix.length > 0 ? $t(translate_prefix + "." + one_option) : one_option) }}</option>
	</select>
</template>
<style>
select.compact-select {
	padding-left: 10px;
	padding-right: 10px;
	border-style: solid;
	border-radius: 5px;
	background-color: #FFF;
	border-width: 1px;
	border-color: #000;
	color: #000;
	margin: 2px;
}
select.compact-select.dark {
	background-color: #000;
	border-color: #FFF;
	color: #FFF;
}
</style>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'CompactSelect',
	data () {
		return {
			value: this.modelValue,
		}
	},
	props: {
		readOnly: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
		},
		modelValue: {
			type: String,
			required: true,
		},
		translate_prefix: {
			type: String,
			default: '',
		},
	},
	emits: {
		"update:modelValue": null,
		"change": null,
	},
	computed: {
		...mapGetters([
			'theme',
		]),
	},
	components: {
	},
	methods: {
		change_value(){
			this.$emit('update:modelValue', this.value);
			this.$emit('change', this.value);
		},
	},
	async mounted(){
		//console.log(this.value);
	},
}
</script>
